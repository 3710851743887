import { Card } from 'antd'
import Image from 'next/image'
import Link from 'next/link'

const Partners = ({ partners }) => {
  return (
    <div className="container">
      <div className="pb-16 pt-48 text-center text-4xl font-bold text-white">合作方</div>
      <Card className="bg-white/40 lg:px-6 lg:py-8" bordered={false}>
        <div className="grid grid-cols-3 gap-1 text-center md:grid-cols-5 lg:gap-3 xl:grid-cols-10">
          {partners.map((partner) => {
            return (
              <Card key={partner?.id} className={`${partner.link ? 'cursor-pointer' : 'cursor-default'} flex h-16 items-center justify-center py-3`} id="partnerDiv">
                <Image src={partner?.picture || ''} alt="" id="partnerPic" className="object-contain" width={80} height={40} />
                <div className="absolute inset-0 flex items-center justify-center opacity-0" id="partnerLink">
                  {partner?.link ? (
                    <Link href={partner?.link} className="text-blue-500">
                      查看官网
                    </Link>
                  ) : (
                    <div className="text-gray-500">敬请期待</div>
                  )}
                </div>
              </Card>
            )
          })}
        </div>
      </Card>
    </div>
  )
}
export default Partners
