import { Space } from 'antd'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { withErrorBoundary } from '@/components/ErrorBounddary'
import Button from '../Button'

const FirstPage = ({ isLogin }) => {
  const router = useRouter()
  return (
    <div className="relative min-h-screen w-full overflow-hidden">
      <div className="container relative z-10 mx-auto flex min-h-screen items-center justify-center px-4">
        <div className="grid h-full w-full grid-cols-1 gap-8 place-self-center lg:grid-cols-5">
          <div className="col-span-1 flex flex-col items-center justify-center gap-10 text-white lg:col-span-2 lg:items-start">
            <div className="text-center lg:text-left">
              <h1 className="mb-4 bg-gradient-to-r from-gray-50 to-orange-400 bg-clip-text text-4xl font-bold text-transparent">AI+电竞+智力运动综合服务商</h1>
            </div>
            <div className="prose prose-lg prose-invert max-w-none rounded-xl bg-white/5 p-6">
              <p className="mb-4 leading-relaxed opacity-90">灵缇互娱(lingti.com)是「AI+电竞+智力运动」综合服务商，旗下拥有多款自主研发软硬件产品：</p>
              <ul className="list-none space-y-2 pl-0">
                <li className="flex items-center gap-2">
                  <span className="inline-block h-2 w-2 rounded-full bg-orange-400" />
                  <span className="font-semibold text-purple-200">灵缇赛事系统</span>
                  <span className="opacity-80">（线上+线下）</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="inline-block h-2 w-2 rounded-full bg-orange-400" />
                  <span className="font-semibold text-purple-200">灵缇电竞加速软件</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="inline-block h-2 w-2 rounded-full bg-orange-400" />
                  <span className="font-semibold text-purple-200">灵缇协会俱乐部管理平台</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="inline-block h-2 w-2 rounded-full bg-orange-400" />
                  <span className="font-semibold text-purple-200">灵缇运动员积分排名系统</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="inline-block h-2 w-2 rounded-full bg-orange-400" />
                  <span className="font-semibold text-purple-200">灵缇复式发牌机</span>
                  <span className="opacity-80">（三代机研发中）</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="inline-block h-2 w-2 rounded-full bg-orange-400" />
                  <span className="font-semibold text-purple-200">协码啦效率软件</span>
                  <span className="opacity-80">(xiemala.com)</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="inline-block h-2 w-2 rounded-full bg-orange-400" />
                  <span className="font-semibold text-purple-200">协码啦AI</span>
                  <span className="opacity-80">(ai.xiemala.com)</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="inline-block h-2 w-2 rounded-full bg-orange-400" />
                  <span className="font-semibold text-purple-200">牌例及AI教学系统</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="inline-block h-2 w-2 rounded-full bg-orange-400" />
                  <span className="font-semibold text-purple-200">扑克直播软硬件方案(研发中)</span>
                </li>
              </ul>
              <p className="mt-4 leading-relaxed opacity-90">同时，灵缇互娱提供赛事、传媒、直播、文创等电竞智力运动专业服务。</p>
            </div>
            <Space size="large" className="mt-6">
              <Button
                type="primary"
                onClick={() => router.push(isLogin ? '/match?is_create=1' : '/login?redirect=match?is_create=1')}
                shape="round"
                className="h-[45px] w-[140px] text-base font-semibold shadow-lg transition-all duration-300 hover:shadow-purple-500/30 sm:h-[55px] sm:w-[220px] sm:text-xl"
              >
                立即办赛
              </Button>
              <Button
                type="link"
                size="large"
                onClick={() => window.open('https://xiemala.com/f/Dsxa1v')}
                className="h-[45px] w-[140px] rounded-full border border-white/20 bg-white/10 text-base font-semibold text-white transition-all duration-300 hover:bg-white/20 sm:h-[55px] sm:w-[220px] sm:text-xl"
              >
                联系我们
              </Button>
            </Space>
          </div>

          <div className="col-span-1 flex items-center justify-center lg:col-span-3">
            <div className="relative">
              <div className="absolute -inset-10" />
              <Image src="/lingti-charactor.png" alt="灵缇互娱" className="relative z-10 h-auto w-full object-contain" width={2000} height={1200} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withErrorBoundary(FirstPage)
