import React from 'react'
import Footer from '@/components/HomePage/Footer'
import FirstPage from '@/components/HomePage/FirstPage'
import Goods from '@/components/HomePage/Goods'
import Image from '@/components/Image'
import Partner from '@/components/HomePage/Partner'
import cover from '@/public/cover.jpeg'
import Device from '@/components/HomePage/Device'
import PhotoWall from '@/components/HomePage/PhotoWall'
import News from '@/components/HomePage/News'
import Adv from '@/components/HomePage/Adv'
import { Button } from 'antd'
import IconFont from '@/components/IconFonts'
import usePartners from '@/hooks/swr/usePartners'
import useLogin from '@/hooks/swr/useLogin'
import useNews from '@/hooks/swr/useNews'

const Home = () => {
  const { data: partners } = usePartners()
  const { data: news } = useNews()
  const { isLogin } = useLogin()
  return (
    <div>
      <Image src={cover} alt="" className="fixed top-0 object-cover" fill />
      <div className="flex">
        <div className="z-40 m-auto w-full">
          <FirstPage isLogin={isLogin} />
          <Adv />
          <Device />
          <PhotoWall />
          <Goods />
          <Partner partners={partners} />
          <News news={news} />
          <Footer />
        </div>
        <Button
          type="link"
          size="large"
          onClick={() => window.open('https://xiemala.com/s/wObhjT')}
          shape="round"
          className="fixed bottom-20 right-6 z-50 h-[60px] w-[60px] border-4 border-white bg-primary text-white"
        >
          <IconFont icon="icon-kefu" />
        </Button>
      </div>
    </div>
  )
}

export default Home
